import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} 每M收益',
      accountEarning: '賬戶收益',
      active: '活躍',
      activeMiner: '活躍礦機',
      amoutPaid: '已支付',
      announcement: '通知公告',
      cannotConnect: '無法連接?',
      urlConfigGpu: ' 支持普通顯卡礦機',
      urlConfigNicehash: ' 為Nicehash特別優化',
      urlConfigGpuDcr: ' 已知支持螞蟻礦機DR3，Claymore，gominer',
      urlConfigNicehashDcr: ' 已知支持Nicehash，芯動科技等礦機',
      customerTitle: '售後服務',
      dailyHashrate: '日算力',
      dashboard: '用戶面板',
      earning: '{coinType} 收益',
      eventConnected: '已連接',
      eventDisconnected: '斷開連接',
      eventMiner: '礦機',
      eventName: '條消息',
      eventSkip: '已跳過',
      eventTitle: '事件通知',
      eventlatestLogs: '以下為歷史消息',
      hastrateChart: '算力圖表',
      history: '支付記錄',
      hour: '{n} 小時',
      hours: '小時',
      inActive: '非活躍',
      lastPaymentTime: '上一次支付時間',
      markAllRead: '全部標記為已讀',
      minerContent:
        '進入挖礦軟件配置界面，填入您的子賬戶（密碼建議留空）和挖礦地址，保存後運行exe程序即可，礦機將在一分鐘內自動添加到礦池網站頁面。',
      minerNameStand:
        '礦機名規則為：子賬戶+英文句號+編號，例如，您的子賬戶是{sub_account}, 可以設置礦機名為{sub_account}.001、{sub_account}.002，以此類推，一個礦機名對應一台礦機。',
      minerName: '礦機名設置參考："{sub_account}.001", "{sub_account}.002".',
      miner: '礦機',
      miner1: 'I 類礦機',
      miner2: 'II 類礦機',
      miners: '礦機數',
      miningAddress: '挖礦地址',
      miningEarnings: '{coinType} 算力收益',
      minute: '分鐘',
      more: '更多',
      networkDiff: '當前難度',
      networkHashrate: '全網算力',
      networkStatus: '網絡狀態',
      nextDifficult: '預測下次難度',
      noMiner: '您需要添加礦機到礦池，然後開始挖礦。推薦使用BTC.com 礦池',
      btcTools: '代理軟件。',
      noEvent: '暫無事件通知!',
      nowHashrate: '當前算力',
      pendingPayouts: '待確認支付',
      poolHashrate: '礦池算力',
      realHashrate: '{  coinType} 實時算力',
      realtime: '實時',
      timeRemain: '距離調整還剩',
      title: '用戶面板 - BTC.com Pool',
      today: '今日預估',
      unpaid: '未支付',
      yesterday: '昨日實收',
      yesterdayEarnTip:
        '昨日收益為國際標準時間昨日0:00-24:00（北京時間昨日8:00到今日8:00）之間的收益。',
      todayEarnTip: 'SHA256算力BTC挖礦收益預估',
      charts: {
        hashrate: '算力',
        minerCharts: '礦機算例曲線',
        myCharts: '我的算力曲線',
        poolCharts: '礦池算力曲線',
        reject: '拒絕率',
        time: '時間',
      },
      yesterday_earn: '昨 日收益',
      yesterday_hashhrate: '昨日有效算力(不含拒絕)',
      earnings_per_100T: '摺合每100T收益',
      //職責聲明
      statement:
        '* 您不得通過我們提供或建議的方式以外的任何方式使用（或嘗試使用）此服務，並且您明確保證不會通過任何違法或不合法方式使用（或嘗試使用）此服務，或為支持任何違法或不合法活動的目的使用此服務。 進入本網站並註冊此服務即表示您承諾並保證您是根據您所在司法轄區法律可以合法獲得此項服務的人士。',
      // 收益預估
      estimatedEarnings: {
        title: '算力收益預估',
        hours24Earnings: '最近24小時預估',
        tip24: '根據幣種最近24小時平均挖礦難度計算',
        realTimeEarnings: '實時預估',
        realTimeEarningsTip: '根據幣種最新挖礦難度計算',
      },
    },
    subAccount: {
      title: '創建子賬戶 - BTC.com Pool',
      createWorker: '創建子賬戶',
      noNode: '您還未選擇節點!',
      noCoinType: '您還未選擇幣種!',
      selectRegion: '選擇地區',
      setWorker: '設置子賬戶名稱',
      enterWorker: '請輸入子賬戶名稱',
      enterAddress: '請輸入收款地址',
      coinTypeEx:
        'BTC.com現已支持 {supportCoins} 挖礦，更多幣種即將推出，敬請期待。',
      regionEx: 'BTC.com在全球都部署服務器，選擇最近的服務器能獲得最好的體驗。',
      workerEx:
        '您設置的子賬戶將綁定於所選地區。您最多可設置2000個子賬戶。這一子賬戶名稱加上礦機編號即為礦機設置頁的礦機名（worker name），如: "{name}.001"，"{name}.002"。',
      workerCaution:
        '* 請注意，同一子賬戶不支持跨地區挖礦，您創建於當前地區的子賬戶在其他區域不存在。如果您在其他地區擁有礦機，請重新創建對應地區的子賬戶。',
      setAddress: '填寫提幣地址',
      save: '創建並挖礦',
      verifyWorker: '子賬戶僅支持由3-20位字母或數字組成',
      option: '選填',
      'optional-BTC':
        '註：比特幣現金（BCH）舊格式地址與比特幣（BTC）地址相似，請謹慎填寫，以免造成丟幣損失。',
      'optional-BCH':
        '註：比特幣現金（BCH）舊格式地址與比特幣（BTC）地址相似，請謹慎填寫，以免造成丟幣損失。推薦使用新格式地址',
      'optional-UBTC':
        '註：比特幣現金（BCH）舊格式地址與比特聯儲（UBTC）地址相似，請謹慎填寫，以免造成丟幣損失。',
      'optional-SBTC':
        '註：比特幣現金（BCH）舊格式地址與超級比特幣（SBTC）地址相似，請謹慎填寫，以免造成丟幣損失。',
      'optional-coin': '註：請謹慎填寫 { coin_type } 地址，以免造成丟幣損失。',
      selectCoinType: '選擇幣種',
      automatic: '自動生成',
      recommandWallet: '推薦使用BTC.com錢包',
      withdrawToExchange: '提現至交易所',
      withdrawToWallet: '提現至錢包',
    },
    tools: {
      // title: '工具 - BTC.com Pool',
      // tools: '工具',
      // fppsPps: 'FPPS與傳統PPS動態對比工具',
      // aboutFpps: 'FPPS比傳統PPS好在哪？',
      // charts: '動態數據 一目了然',
      // watchCharts: '點擊對比',
      // miniCalc: '挖礦計算器',
      // miniCalcExpress: '結合難度與幣價，通過算力、功耗、電費計算挖礦利潤',
      // miniMiniCalc: '迷你挖礦計算器',
      // miniMiniCalcExpress: '快速計算挖礦利潤，僅考慮算力、難度與幣價',
      // strat: '開始',
      // btcTool: 'BTC.com 礦池批量工具',
      // btcToolIntroduce: '批量處理礦機後台配置，方便 快捷',
      // download: '點擊下載'
      btcSmartAgent: 'BTC 智能代理',
      agentExpress: '高效透明 <br/> 單台礦機可見',
      agentExpressBanner:
        '高效透明 <i style="margin-right:20px"></i> 單台礦機可見',
      bit: 'bit',
      document: 'windows 版幫助文檔',
      poolApp: 'BTC.com 礦池App  全功能App',
      poolRealTime: '實時查看礦池、礦機數據',
      poolOpeation: '支持礦機刪除、修改等操作',
      poolComming: '已上線推送報警功能',
      poolDownlaod: '點擊下載',
      title: '工具 - BTC.com Pool',
      tools: '工具',
      fppsPps: 'FPPS與傳統PPS動態對比工具',
      aboutFpps: 'FPPS比傳統PPS好在哪？',
      charts: '動態數據 一目了然',
      watchCharts: '點擊對比',
      miniCalc: '挖礦計算器',
      miniCalcExpress: '結合難度與幣價，通過算力、功耗、電費計算挖礦利潤',
      miniMiniCalc: '迷你挖礦計算器',
      miniMiniCalcExpress: '快速計算挖礦利潤，僅考慮算力、難度與幣價',
      start: '開始',
      btcTool: 'BTC.com 礦機批量工具',
      btcToolIntroduce: '批量處理礦機後台配置，方便 快捷',
      download: '點擊下載',
      ipToolsTitle: 'BTC.com礦機IP批量修改工具',
      ipToolsDes: '支持全系列螞蟻礦機、佔用IP修改、配置直連礦池',
    },
    miners: {
      createGroup: '創建新分組',
      deleteGroupTitle: '刪除分組',
      editGroupTitle: '編輯分組',
      all: '全部',
      default: '未分組',
      addGroup: '創建新分組',
      addGroupInfo: '注意：選中的礦機將自動添加到新分組中',
      groupNameLabel: '分組名',
      addGroupEmpty: '組名稱不能為空',
      groupRejected: '實時拒絕率', // Real time rejection rate
      groupNameLimit: '分組名必須在 3 到 20 個字之間',
      inactive: '不活躍',
      active: '活躍',
      dead: '失效',
      moveTo: '移動到',
      remove: '刪除',

      allTooltip: '包含：活躍礦機 ({active}) + 不活躍礦機（{inactive}）',
      inactiveTooltip: '超過10分鐘未提交計算結果（Share）的礦機數量。',
      activeTooltip: '正常提交計算結果（Share）的礦機數量',
      deadTooltip: '超過24小時未提交計算結果（Share）的礦機數量',

      home: '首頁',
      miners: '礦機',

      workerName: '礦機名',
      Hashrate: '實時算力',
      Rejected: '實時拒絕率',
      dailyHashrate: '日算力',
      firstShare: '首次連接時間',
      LastShare: '最近提交時間',
      groupName: '分組名',

      delMillTitle: '確認要刪除這些礦機信息嗎？',
      delMillContext: '該操作會刪除您選中的所有礦機，請謹慎操作。',
      delMillFailed: '刪除失敗',

      delGroupTitle: '你確定刪除 「{name}」 分組嗎？',
      delGroupContext: '分組內礦機將全部移動到未分組中',

      moveTitle: '移動礦機',
      notChecked: '請勾選礦機,支持批量操作',
      moveContext: '該操作會移動所有選中礦機到 {groupName} 組',
      moveFaild: '不能移動到當前分組',

      searchPlaceholder: '輸入礦機名',
      reportTime: '此報告生成時間',
      refresh: '刷新',
      editGroupName: '修改分組名稱',
      workListEmpty: '該分組未檢測到礦機',
      exist: '該分組已存在',
      goOldVersion: '返回舊版',
      dataExport: `「{name}」 分組算力數據導出`,
      exportTip:
        '請選擇需要導出的數據的時間區間，最多只能導出30天數據。導出的文件格式為CSV。',
      exportTip2:
        '當日算力的統計時間為00:00 - 23:59，其中今日算力的統計時間為00:00 - 導出時間。',
    },
    minerInfo: {
      miners: '礦機',
      hashrate: '單機算力',
      runningCondition: '運行狀況',
      min: '分鐘',
      hour: '{n}小時',
      hashrateAxis: '算力',
      rejected: '拒絕率',
      status: '狀態',
      lastShare: '最近提交時間',
      lastShareIP: '最近提交IP',
      hashrateChart: '算力圖表',
      realTime: '實時',
      trans: {
        seconds: '秒',
        mins: '分鐘',
        hours: '小時',
        ago: '前',
        ACTIVE: '活躍',
        INACTIVE: '不活躍',
        DEAD: '失效',
      },
    },
    earningHistory: {
      title: '收益歷史 - BTC.com Pool',
      earn: '挖礦收益',
      Unpaid: '未支付',
      EarningsToday: '預估已挖',
      EarningsTodayTip:
        '從UTC的0點到現在，預計已經挖出的PPS收益數量，可能與您的實際收益存在偏差。',
      EarningsYesterday: '昨日收益',
      Time: '挖礦日期',
      NetworkDifficulty: '全網難度',
      Earnings: '收益',
      Payment: '支付時間',
      payWithOTCAmount: '以 {otcType} 結算收益: {amount}',
      Mode: '模式',
      Address: '地址',
      PaidAmount: '收益數額',
      yesterday: '昨日收益為UTC時間前一天0:00 - 24:00的收益。',
      unpaidReason: {
        PENDING_NON_ADDRESS: '未設定收款地址，將存入餘額。',
        PENDING_CHANGED_ADDRESS:
          '地址改動後將在48小時後開始支付。警告：如果地址並非由你本人改動，你的賬號（郵箱/手機）可能已被攻破。請馬上使用其他郵箱或手機號註冊新賬戶，將礦機切換到新賬戶。',
        PENDING_NOT_ENOUGH:
          '金額小於您設置的起付金額（默認 :limit ），將存入餘額。',
        PENDING_POOL_SETTLE_FAULT:
          '因礦池原因，導致這部分金額誤發，後續將不再發放。',
      },
      depositeAmount: '存入餘額',
      diffChg: '難度變化: ',
      equalsPps: '摺合PPS',
      Chg: '日變動',
      status: '支付狀態',
      paid: '已支付',
      waitingPay: '待支付',
      reason: '原因',
      fppsMoreEarn: '{mode} 額外收益:',
      fppsContain: '相比PPS模式，{mode}模式收益增加{pps96}',
      noEarningsInformation: '暫無收益信息',
      earningDetail: '收益詳情',
      Equivalent: '等值數額',
      extraProfits: '額外收益',
      autoSwap: '閃兌結算',
      original: '原幣結算',
      Swapped: '已兌換',
      swapping: '兌換中',
      otcEarningTip:
        '閃兌結算機槍模式目標幣種收益根據Matrixport賬單日實際閃兌匯率計算',
      originEarningTip:
        '原幣結算機槍模式目標幣種收益根據賬單日8:00AM Matrixport的閃兌匯率計算',
      exportEarningsData: '收益數據導出',
      exportTip: '最多可支持180天數據，導出的文件格式為XLS。',
      originStatusTip:
        '收益已通過閃兌功能兌換為目標幣種收益，詳情通過機槍模式下收益頁面查詢',
      swappingTip: '當前收益正進行兌換操作或由於收益過低未達到兌換最低金額',
      swappedTip:
        '收益已通過閃兌功能兌換為目標幣種收益，詳情通過機槍模式下收益頁面查詢',
      // upgrade TODO 收益改版新增
      settlementHashrate: '結算算力',
      addressRatio: '地址比例',
      addressEarnings: '地址收益',
      paymentTime: '支付時間',
      TimeTip:
        '挖礦日期採用的是UTC時間，例如2020年1月1日是指UTC時間2020年01月01日 00:00:00 - 2020年01月02日 00:00:00',
      EarningsTip:
        '收益數額由「BTC.com結算中心」根據多項因素綜合計算得出：收益分配方式、全網難度、結算算力、區塊基礎獎勵、區塊交易費獎勵以及礦池費率等等，與您在收益計算器中計算的理論收益可能存在偏差。',
      // upgrade TODO 邀请新增
      inviteEarning: '返佣收益',
      inviteWorkerAccount: '礦工子賬戶',
      inviteRebateRatio: '返佣比例',
      inviteRebateAmount: '返佣數額',
      rebateExportTip: '最多可支持60天數據，導出的文件格式為XLS。',
    },
    poolStats: {
      title: '礦池統計 - BTC.com Pool',
      poolStats: '統計',
      hashrate: '算力',
      coinType: '幣種',
      miners: '礦機',
      found: '挖礦所得',
      hashrateChart: '算力圖表',
      blocksRelayed: '廣播區塊',
      height: '高度',
      time: '時間',
      blocks: '區塊',
      blockHash: '區塊哈希',
      ddaScore: 'DAA分數',
      reward: '區塊獎勵',
      day: '日',
      region: '報塊地區',
      lucky: '幸運值',
      btc: '比特幣',
      ltc: '萊特幣',
      nmc: '域名幣',
      zec: '零幣',
      bcc: '比特幣現金',
      bch: '比特幣現金',
      eth: '以太坊',
      etc: '以太經典',
      dcr: 'DCR',
      doge: '狗狗幣',
      grin: 'GRIN',
      sbtc: '超級比特幣',
      ubtc: '比特幣聯儲',
    },

    // 守護者
    guardian: {
      expiredDeadline: '(:day 自動刪除)',
      guardianRemark:
        '您可以將全部或部分子賬號信息（包括算力、礦機等）共享給其它用戶，該用戶僅可以查看但無法改動任何信息',
      createGuardian: '添加共享用戶',
      remark: '備註：',
      user: '用戶:',
      manage: '管理',
      status: '狀態:',
      theGuardian: '共享賬戶:',
      creationDate: '創建日期:',
      datePrevious: '最近使用日期:',
      bounded: '已綁定',
      delete: '刪除',
      deleteGuardianLink: '刪除共享賬戶鏈接',
      createGuardianInvitation: '生成共享賬戶邀請鏈接',
      guardianAccountManagement: '共享賬戶管理',
      modifyRemark: '修改備註名稱',
      validateRemarkEmpty: '備註名稱不可為空',
      enterRemark: '輸入備註名稱',
      noteInformation:
        '提醒： 該名稱用於區別不同共享賬戶，建議使用對方的聯繫方式等信息。',
      confirm: '確認',
      create: '生成',
      cancel: '取消',
      guardianSuccessfullyCreated: '成功創建共享賬戶邀請鏈接',
      copyLink: '複製鏈接',
      authorized: '刪除後，他人會失去對您子賬戶的管理權限',
      noteSendGuardian:
        '提醒： 請將該鏈接發送給有礦機管理需求的可信人員。該鏈接僅供訪問一次，若對方提示失效，請重新生成。',
      close: '關閉',
      subaccount: '子賬戶',
      coinType: '幣種',
      region: '地區',
      guardianStatus: '共享狀態',
      guardianSelectExpress:
        '通過分享狀態中的開關，您可以自由的選擇他人能夠管理的子賬戶（默認設置為全部子賬戶均可訪問）',
      invitTitle: '共享給他人',
      acceptTitle: '接受他人共享',
      losePower: '刪除後你將失去對方子賬戶的管理權限。',
      show: '顯示',
    },
    // 綁定守護者邀請鏈接
    guardianBind: {
      bindingGuardianInvitation: '綁定共享賬戶邀請鏈接',
      advance: '高級',
      guardianAccountManagement: '共享賬戶管理:',
      confirmGuardianBind: '確定守護並綁定賬戶',
      remark: '備註名稱',
      enterRemark: '輸入備註名稱',
      suggestName:
        '推薦使用對方的名字，方便統一管理。若不填寫將默認使用對方的手機號或電子郵箱。',
      guardian: '綁定',
      cancel: '取消',
      confirm: '確定由',
      manageView: '管理維護您的所有子賬戶？',
      suggestUsingName:
        '推薦使用對方的名字，方便統一管理。若不填寫將默認使用對方的手機號或電子郵箱。',
      sms: '手機',
      mail: '郵箱',
      code: '{mode}驗證碼',
      send: '獲取',
    },
    account: {
      subAccountManage: '子賬戶管理',
      currentAccount: '賬戶',
      locationRegion:
        '您當前位於 <strong>{coin}</strong> <strong>{region}</strong> 服務器',
      locationEx:
        '每個子賬戶對應固定的幣種和區域，一旦創建完成，幣種和區域將無法修改。如果有其他需要，可以創建新的子賬戶。',
      general: '通用版管理',
      advance: '專業版管理',
      create: '新建子賬號',
      algorithm: ' 算法',
      oneButtonSwitch: '一鍵切換',
      addOthersCoins: '添加其它算法幣種',
      supportCoins: '支持',
      hiddenSubAccounts: '隱藏子賬戶管理',
      hashAlert: '運行良好',
      viewHideSubAccount: '查看隱藏子賬戶',
      addSubAccount: '添加子賬戶',
      recover: '還原',
      latestAlert: {
        hashrate: '實際算力 {symbol} {expect} {unit}',
        miners: '實際礦機數量 {symbol} {expect} 台',
      },
    },
    settings: {
      subAccount: '子賬戶',
      currency: '幣種',
      region: '地區',
      walletAddressAndMinimumPayment: '收款地址',
      walletAddress: '收款地址',
      shareData: '共享賬戶',
      shareDataTip:
        '您可以將全部或部分子賬戶信息分享給他人，他人僅可查看數據； 您也可以只讀方式接收他人的共享。',
      alertSettings: '警報設置',
      mywatcher: '觀察者設置',
      apikey: 'API KEY',
      payWithBtc: '以BTC結算',
      //wallet address & minimum payment
      setAddress: '請設置 {coinType} 收款地址',
      address: '收款地址',
      createAddress: '新建地址',
      modifyAddress: '更改地址',
      modify: '修改',
      minPay: '起付金額',
      lastUpdateTime: '地址修改時間',
      updateBitconAddressTip:
        '注意：為了資金安全，本次操作後48小時內暫停打款，您的收益將作為餘額，在48小時後的第一個結算日支付。',

      old: '舊地址',
      new: '新地址',
      continue: '下一步',
      addAddress: '填寫',
      enterCode: '請填寫驗證碼',
      newBit: '您的新地址',
      way: '我們將發送驗證碼來確認此次修改 , 請選擇驗證方式:',
      sendVerifyCode: '驗證碼已發送到{contactType}',
      sms: '手機',
      mail: '郵箱',
      mailVia: '發送驗證碼到郵件',
      smsVia: '發送驗證碼到手機',
      errorLater: '請稍後再試 ...',
      updateSuccess: '地址修改成功',
      // 多地址收款
      multAddress: {
        tag: '支持多地址',
        addMultAddress: '添加地址',
        adjustProportion: '調整比例',
        adjustMinPay: '調整起付額',
        address: '地址',
        proportion: '比例',
        remark: '備註',
        lastUpdateTime: '修改時間',
        operation: '操作',
        emptyText: '未添加收款地址',
        emptyTextForMult: '暫無數據，最多可添加20個地址',
        cancel: '取消',
        nextStep: '下一步',
        delete: '刪除',
        modify: '修改',
        newAddressPlaceholder: '新地址',
        addressMaxCountTip:
          '當前一個子賬戶最多可添加{maxCount}個收款地址，如需要支持更多地址，請聯繫客服 support@connectbtc.com。',
        updateBitconAddressTip:
          '為了資金安全，本次操作後應付收益將暫停支付，統一在48小時後的第一個結算日完成支付。',
        emptyRemark: '未添加備註',
        noAddressForAdjust: '尚未添加地址，請添加地址後再調整比例或起付額。',
        balanceMoveTip:
          '當前子賬號的未支付的數字貨幣將轉到「新地址1」中，請確認無誤後再操作。',
        confirmModalTitle: '安全驗證',
        authentication: '身份驗證',
        operationConfirm: '確認操作',
        addAddressConfirmTip: '您正在添加{count}個收款地址',
        modifyProportionConfirmTip: '您正在調整各個收款地址的比例',
        deleteAddressConfirmTip: '您正在刪除地址<br/>{address}',
        selectBalanceMoveAddress: '選擇接收數字貨幣的地址',
        selectBalanceMoveAddressTip:
          '正在刪除的地址的未支付金額為{balance}{coinType}，本次操作後將於24小時內轉入以上選擇的新地址。',
        cannotDeleteAddress: '無法刪除該地址',
        deleteAddressRule: '僅可刪除收款比例等於0.00%的地址。',
        // deleteAddressRuleDetail:
        //   '1. 收款比例等於0.00%；<br/>2. 同子賬號下的機槍挖礦地址未設置為本地址。',
        // usedBySmart:
        //   '當前子賬號的機槍挖礦選擇的是當前地址，本次修改將同步修改機槍挖礦地址。',
        operationSuccess: '操作成功',
        validate: {
          emptyAddress: '請填寫新地址',
          emptyAddressForUpdate: '地址不能為空',
          emptyProportion: '請填寫地址',
          emptyProportioAfter: '的比例',
          proportionInvalidate:
            '每個地址的比例必須大於或等於0%，所有比例之和必須等於100%',
          maxCount: '每個子賬號最多可設置20個地址',
          duplicateAddressPrefix: '同一個地址只能添加1次，地址',
          duplicateAddressSuffix: '被添加了多次。',
          limitAddress: 'This type of address is not supported at this time',
        },
      },

      //min pay
      currentMinPay: '當前起付金額:',
      minPayAllSubAccount: '所有比特幣(BTC)子賬戶使用相同起付金額',

      //Share to watcher
      watcher: '觀察者',
      availableWatchers: '當前可用',
      watcherExpress:
        '* 觀察者可以通過以下鏈接訪問你的數據，但無法操作、改動你的賬戶。',
      createWatcher: '創建新的觀察者',
      modifyWatcher: '編輯權限',
      authoritySettings: '權限設置',
      setWatcherName: '為觀察者設置一個備註名',
      watcherName: '觀察者備註名',
      watcherNameTip: '字符限制 2~20',
      notEmptyWatcherNote: '觀察者備註名不能為空',
      tips: '提示：',
      tip_dashboard:
        '1. 「用戶面板」不包括「未支付」、「已支付數量」等收益信息；',
      tip_earning:
        '2. 「收益信息」包括各地址的「未支付」、「已支付數量」等信息。',
      moreSettings: '更多設置',
      guardianRemark:
        '您可以將全部或部分子賬號信息（包括算力、礦機等）共享給其它用戶，該用戶僅可以查看但無法改動任何信息',
      guardian: '共享賬戶',
      shareAccountsSetting: '共享賬戶',
      accessKey: 'Access Key',
      generateAK: '生成 Access Key',
      puid: 'PUID',
      apiDoc: 'API 文檔',
      confirmDeleteWatcher: '確認刪除該觀察者賬戶?',
      watcherAuths_Dashboard: '用戶面板',
      watcherAuths_Dashboard_Tip: '(不含收益信息)',
      watcherAuths_Miners: '礦機頁面',
      watcherAuths_Earnings: '收益信息',
      watcherAuths_GetCoin: '贈幣',

      // Alert setting
      alertItem: '報警項設置',
      alertContacts: '報警聯繫人',
      hashrateAlert: '算力報警',
      minerAlert: '礦機數量報警',
      alertFrequency: '報警頻率',
      alertFrequencyTip:
        '由於政策原因，部分國家地區的手機短訊報警頻次固定為24小時一次，請知悉。',
      warnMeWhenHashrate: '算力警戒線≤',
      warnMeWhenActiveMiner: '活躍礦機數量警戒線≤',
      dontWarnMeTwice: '兩次報警最小間隔',
      hours: '小時',
      apply: '應用',
      operateFailed: '操作失敗!',
      contactsList: '聯繫人列表',
      cancel: '取消',
      ok: '確定',
      save: '保存',
      reset: '重置',
      newContact: '新增聯繫人',
      editContact: '修改聯繫人',
      deleteContact: '刪除聯繫人',
      confirmDeleteContact: '確認刪除該報警聯繫人?',
      name: '稱謂/備註',
      phone: '電話',
      email: '郵箱',
      validateAlert: {
        nameEmpty: '稱謂/備註不能為空',
        phoneRegionFormat: '國際區號格式不正確',
        phoneFormat: '電話格式不正確',
        emailFormat: '郵箱格式不正確',
        contactEmpty: 'email 和 電話號碼 必須填寫一個',
      },
      max_email_contacts: '最多支持10個郵件聯繫人',
      max_phone_contacts: '最多支持5個電話聯繫人',
      enabled_alert_need_hashrate: '未檢測到算力，無法開啟報警。',
      alertItemUpdateSuccess: '報警項設置成功',

      done: '提交',

      okay: '確定',
      emptyAddress: '地址不能為空',
      invalidAddress: '錯誤的地址',
      emptyCode: '驗證碼不能為空',
      wrongCode: '驗證碼錯誤',
      emailVia: '發送郵件到',
      textVia: '發送短訊到',
      help: '幫助',

      contact: '聯繫方式:',
      close: '關閉',
      receivedress: '收款地址:',

      todayUpdateMinPay: '從今日起挖礦收益的起付金額已更改為:{minPay}',
      updateAddress: '地址修改時間:',
      // pay with btc
      payWithBtcTip:
        '* 開啟以BTC結算之後，您的挖礦收益將在每天的固定時間折算成比特幣，體現在收益記錄中，並支付到以下獨立的BTC結算地址上，一般情況下為每天上午的12:00，特殊情況時會另行通知。',
      convertedCurrency: '兌換幣種: ',
      setBtcAddressTip: '請設置BTC收款地址',
      btcAddress: '比特幣地址: ',
      conversionRate: '參考匯率',
      convert: '兌換',
      getValue: '您將得到 ',
      conversionTip: '* 兌換匯率僅供參考，以實際結算結果為準.',
      lastUpdate: '最後更新於: ',
      on: '開',
      off: '關',
      turnOnPaywith: '確定要開啟以 BTC 支付嗎?',
      turnOffPaywith: '確定要關閉以 BTC 支付嗎?',
      payOn: '開啟',
      payOff: '關閉',
      resend: '重新發送',
      send: '發送驗證碼',
      resendTip: '如未收到驗證碼，請在倒計時結束後點擊重新發送',

      // 一鍵切換 One Switch
      oneSwitch: {
        attention: '請注意',
        optionCurrentMode: '請選擇切換模式',
        switch: '您即將切換算力到 {destMode}',
        switchAnotherMining: '正在切換到 {destMode} 模式中...',
        ethSwitchWarning:
          '警告：若您的礦機不支持切換DAG，會導致收益為零。需手動重啟礦機，才可恢復正常。（已知螞蟻礦機E3不支持，由ETC切換到ETH，需手動重啟）',
        ethSwitchWarningLinkTxt: '詳情請點擊',
        hashsHaveSwitched: '算力已切換到 {destMode} 挖礦;',
        checkSubAccount:
          '請查看子賬號 {subAccountName}- {subAccountRegionNode}',
        enter: '進入',
      },
      // 機槍收款地址
      smartAddress: {
        // autoSwapSettlement: '閃兌結算',
        original: '原幣結算',
        autoTip: '機槍收益幣種每日兌換為目標幣種進行結算',
        originalTip: '機槍收益以機槍支持幣種BTC、BCH、BSV進行結算',
        originalCurrency: '來源幣種',
        targetCurrency: '目標幣種',
        earningsInSmartPool: '機槍收益',
        activated: '已開啟',
        unactivated: '已關閉',
        modifyTip:
          '機槍閃兌結算目標幣種與同子賬戶下相同幣種收款地址關聯並保持一致',
        autoHistory: '是否閃兌歷史收益？',
        notice1:
          '為了安全原因，修改地址後48小時內不予支付，您的收益將作為餘額，在48小時後的第一個結算日支付。',
        notice2:
          '開啟閃兌功能，將在當日結算中將機槍挖礦收益自動兌換為目標幣種，點擊「確認」將視為同意{fundLink}。',
        notice3:
          '機槍模式下的收款地址與同子賬戶下幣種收益地址關聯並保持一致(如設置了多地址，將與第一個地址保持一致）',
        originalNotice1:
          '機槍模式下的收款地址與同子賬戶下幣種收益地址關聯並保持一致(如設置了多地址，將與第一個地址保持一致）',
        currentPayoutMode: '當前結算模式: {mode}',
        fundLink: `《BTC.com資金兌換授權協議》`,
        originBtn: '開啟原幣結算',
        otcBtn: '開啟閃兌結算',
        otcSuccess: '機槍模式閃兌結算功能已開啟，將以BTC作為收益付款幣種。',
        otcModalNotice2: `開啟閃兌歷史收益後，賬戶中歷史收益參與閃兌；未開啟閃兌歷史收益，僅最近結算周期內收益參與閃兌。`,
        originSuccess:
          '機槍模式原幣結算功能已開啟，將以算力實際挖礦幣種作為收益付款幣種。',
        // 2021-06-04新增
        mode_otc: '閃兌結算',
        mode_origin: '原幣結算',
        settlementMode: '結算模式',
        settlementModeDescription: '結算模式說明',
        originDescription:
          '原幣結算：結算為實際挖礦的幣種，幣種範圍為BTC、BCH和BSV。',
        autoSwapDescription:
          '閃兌結算：先根據機槍算法進行BTC/BCH/BSV三個幣種動態挖礦，最後統一兌換為BTC進行結算。',
        settlementSwitch: '結算模式切換',
        originalSettlement: '原幣結算',
        autoSwapSettlement: '閃兌結算',
        sha256SmartPoolCurrency: 'Sha256機槍幣種',
        currency: '幣種',
        address: '地址',
        addressRules: '地址規則',
        switchAddress: '切換地址',
        noAddressTip: '同子賬戶下的{coin}幣種未添加地址',
        noAddressSelected: '未選擇地址',
        selectFrom: '從同子賬戶下的{coin}地址列表中選擇',
        settingTip:
          '提示：如果您要將「機槍挖礦收款地址」與「同子賬戶下單幣種挖礦地址」區分開，請到單幣種地址管理中添加一個新的地址，並將收款比例設置為0，然後回到此處切換為新地址即可。',
      },
    },
    homepage: {
      btcSmartAgent: 'BTC智能代理',
      agentExpress: '高效透明   單台礦機可見',
      bit64: 'Win7及以上 64位',
      bit32: 'WinXP 32位',
      document: 'windows版幫助文檔',
      accessLinuxVersion: 'Linux版本安裝直接訪問',
      cuttingEdgeTechnology: '領先的核心技術',
      latestArchitecture: '最新原創架構<br/>俯瞰百家所長',
      openSourceCode: '代碼開源<br/>公開公平',
      highTestPressure: '超高壓力測試<br/>堅若磐石',
      lowOrphanedRate: '超低孤塊率<br/>快如閃電',
      transparentAgent: '全新配套軟件<br/>透明代理',
      standardsServices: '礦業服務新標準',
      customerHotline: '客服電話',
      highHashrateConsultation: '大客戶招商',
      professionalCustomerService: '真正有專業客服的礦池',
      bestVipServer: '業內最高規格VIP服務器',
      oneToOne: '業內頂尖研究者一對一分析案例',
      appAvailable: '礦池App讓你躺着管理',
      earningsTimelyEveryday: '準點打款 風雨無阻',
      transnationalTeamOperation: '首個由跨國團隊支持的礦池',
      currencies: '幣種',
      dailyEarnings: '日收益',
      hashrate: '算力',
      hashrateChart: '算力圖表',
      explorer: '瀏覽器',
      prices: '幣價',
      earningsModels: '收益模式',
      minPayment: '最小起付金額',
      help: '幫助',
      notePaytime: '每日 0:00-8:00 (UTC)支付昨日收益',
      yuanKwhElectric: '元/千瓦·時(電費)',
      todayEarnings: '日收益約:',
      piece: '台',
      signIn: '登錄',
      signUp: '註冊',
      power: '功耗',
      hashrateProfitEst: '算力收益參考',
      hashrateProfitEstTips: '可能与您的实际收益存在偏差，仅供参考。',
      miningProfitEst: '主流礦機收益參考',
      fee: '費率',
      desc: '全球領先的多幣種綜合性服務礦池',
      tutorial: '教程',
      diff: '當前難度',
      nextDifficultyEstimated: '預計下次難度',
      dateToNextDifficulty: '下次難度剩餘時間',
      Algorithm: '算法',
      backup: '備用端口',
      whyMe: {
        title: '為什麼選擇BTC.com礦池',
        leadingTechnology: '領先技術',
        convenientManagement: '便捷管理',
        revenueAssurance: '收益保證',
        professionalServices: '專業服務',
        desc1: `代碼開源、免費礦機礦場管理軟件、官方定製版超頻固件大幅提升收益、區塊鏈數據和技術服務提供商。`,
        desc2:
          '簡潔的App和網頁端操作，手機號/郵箱輕鬆註冊賬戶，獨立的報警功能、收益地址、礦機分組與共享數據的觀察者鏈接。',
        desc3: `真實透明的挖礦數據，先進的FPPS收益模式，強大的結算中心，準點打款風雨無阻。`,
        desc4: `真正擁有專業客服團隊的礦池，業內最高規格的VIP定製化服務，業內頂尖研究者一對一分析案例。`,
      },
      app: {
        title: '礦池 App',
        description: '全球領先的多幣種綜合礦池',
        property1: '支持多幣種挖礦，多子帳戶管理，觀察者鏈接分享輕鬆管理',
        property2: '幣種全面數據實時展示，更快掌握挖礦動態',
        property3: '全新交互界面，使用體驗更加流暢',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: 'ETH的結算模式為FPPS/PPS+雙模式，當前模式為FPPS模式；平台會根據鏈上交易費用、本礦池算力份額、市場行情、用戶收益等綜合因素調整結算模式。',
            'PPS+':
              'ETH的結算模式為FPPS/PPS+雙模式，當前模式為PPS+模式；平台會根據鏈上交易費用、本礦池算力份額、市場行情、用戶收益等綜合因素調整結算模式。',
          },
        },
        ltc: {
          giftCoin: '+DOGE',
        },
      },
      globalZone: '全球區',
      zilDiff1: '分片',
      zilDiff2: 'DS',
      etcMinerAddressTips: '只挖ETC的設備連接該挖礦地址',
      zilMinerAddressTips: 'ETC和ZIL雙挖的設備連接該挖礦地址',
    },
    advanceAccount: {
      title: '子賬戶管理 - BTC.com',
      all: '全部幣種',
      unit: '單位',
      subAccount: '子賬戶',
      coin: '幣種',
      hashrate: '算力',
      allMiners: '全部礦機',
      guard: '備註名稱',
      activeMiners: '活躍',
      inactiveMiners: '非活躍',
      deadMiners: '失效',
      region: '地區',
      more: '更多',
      setting: '設置',
      totalHashrate: '總算力',
      search: '搜索',
      status: '狀態',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: '即日起在BTC.com礦池挖礦(比特幣現金，比特幣)的同時免費贈送域名幣(NMC), 亦來雲(Elastos)。',
        bch: '即日起在BTC.com礦池挖礦(比特幣現金，比特幣)的同時免費贈送域名幣(NMC), 亦來雲(Elastos)。',
        ltc: '即日起在BTC.com礦池挖礦(萊特幣LTC)的同時免費贈送狗狗幣(Doge)，以PPLNS方式結算。',
        bcha: `<p>BCHA（BCHABC）是BCH的分叉幣，相關介紹請訪問 <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a>。</p>
          <p><b>提現時間:</b><br/>2020年12月7日上午08:00 到 2021年1月7日上午08:00 (新加坡時間)
          </p>`,
      },
      beta: '內測中',
      whatsThis: '這是什麼?',
      address: '地址:',
      newAddress: '新地址',
      addressComplete: '為了保證您的收益不受任何損失，請儘快填寫免費贈幣地址。',
      bchaAddressComplete:
        '2021年1月7日後，未填寫地址的用戶，BCHA將清零，不再補發。為了保證您的收益不受任何損失，請儘快填寫免費贈幣地址。',
    },
    watcher: {
      expired: '觀察者權限已過期',
      incorrect: '觀察者鏈接錯誤',
    },
    activity: {
      pointsToday: '今日積分',
      pointsSum: '累計積分',
      ranking: '排名',
      earnings: '收益',
    },
    minerRanking: {
      title: '礦機收益排名',
      electric: '日電費',
      dailyEarning: '日收益',
      priceShutdown: '關機幣價',
      ALL: '全部',
      CNY: '人民幣 CNY',
      USD: '美元 USD',
      desc: '收益由高到低',
      asc: '收益由低到高',
      electricPercent: '電費佔比',
      searchTip: '請輸入礦機',
      updated: '更新於',
      coin: '幣種',
      emptyShow: '沒有符合條件的礦機',
      rank: '排名',
      miner: '礦機',
      power: '功耗',
      hashrate: '算力',
      rev24h: '日產出',
      energy: '電費支出',
      incomeWithdoge: '計入DOGE收益',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
