import flatten from 'flat';
const commonMessage = {
  common: {
    locale: '한국어',
    header: {
      home: '마이닝풀 메인 페이지',
      dashboard: '유저 페이지',
      getCoin: '코인 증정',
      help: '도움말',
      miners: '채굴기',
      poolStats: '통계',
      commissionEarnings: '리베이트 소득',
      miningEarnings: '채굴 수익',
      service: '고객센터',
      tools: '툴',
      setting: '설정',
      guardian: '공유',
      guardianPermission:
        '귀하는 계정 데이터 확인 권한만 보유하고 있으며, 어떠한 편집 조작도 진행할 수 없습니다.',
      watcher: '관찰자',
      watcherPermission:
        '관찰자는 서브 계정 데이터 확인 권한을 보유하고 있으나, 어떠한 편집 조작도 진행할 수 없습니다.',
      userCenter: '유저 센터',
      SignOut: '나가기',
      accountSetting: '계정 설정',
      poolManagePanel: '마이닝풀 관리 페이지',
      watcherMode: '관찰자 모드',
      scanToService: 'QR코드 스캔 후 도움말 확인하기',
      bitdeer: 'Bitdeer 스페셜 칼럼',
      hashnest: '해시네스트',
      app: 'App',
      explorer: '브라우저',
      getBCHA: 'BCHA 수령',
      candy: '캔디',
      alertBar:
        '<b>8월10일 22:00 - 24:00(UTC+8)</b>, BTC.com 마이닝풀에서 대규모 데이터 점검을 실시할 예정입니다. 일부 기능은 사용할 수 없으며 채굴 서비스는 영향을 받지 않습니다. <a href="https://help.pool.btc.com/hc/zh-cn/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">자세한 사항을 클릭 후 확인하세요. </a>',
      introTip:
        '유저 페이지, 채굴기, 수익, 서브 계정 설정 등 기능은 「마이닝풀 관리 페이지」에 통합되어 있습니다.',
    },
    nav: {
      'nav-explorer': '브라우저',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': '마이닝풀서비스',
      'nav-pool-home': '마이닝풀 메인 페이지',
      'nav-pool-data': '마이닝풀데이터',
      'nav-pool-candy': '캔디 커뮤니티',
      'nav-pool-tools': '툴&소프트웨어',
      'nav-pool-cs': '고객센터',
      'nav-wallet': '월렛',
      'nav-navigation': 'Блокчейн-навигация',
      'nav-navigation': '항해',
      'nav-inscriptions': '쓰다',
      'nav-app': 'APP',
      'nav-collaborate': '협력',
      'nav-brc20': 'BRC-20',
      'nav-tools': '도구',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
    },
    menu: {},
    setting: {
      balance: '현재 잔액',
      coinAddress: '{coinType} 월렛 주소',
      setAddress: '{coinType}월렛 주소 설정을 클릭하세요.',
      multAddressEnabled: '여러 개의 주소 결제가 활성화되었습니다.',
      menu: {
        hide: '숨기기',
        address: '월렛 주소',
        minPay: '최소 결제 금액',
        payWithBtc: 'BTC로 결제',
        earingsHistory: '수익',
        shareData: '공유 계정',
        watcher: '감시자 설정',
        apikey: 'API KEY',
        alertSettings: '경고 설정',
        onebuttonSwitch: '전부 변경',
        userCenter: '유저 센터',
        signOut: '로그아웃',
        delete: '삭제',
      },
      confirm: '확인',
      cacel: '취소',
      deleteConfirm: '삭제 확인',
      cannotDelete: '해당 서브 계정을 삭제할 수 없습니다.',
      cannotDeleteView: '현재 확인 중인 서브 계정을 삭제할 수 없습니다.',
      deleteCondition:
        '서브 계정을 삭제할 수 없습니다. <span class="subAccountName">{{accountMessage}}</span> 다음의 두 조건을 동시에 만족시킬 수 있는 서브 계정만 삭제할 수 있습니다.',
      cannotDeleteReason:
        '1. 해당 서브 계정의 최근 180일 해시레이트가 0입니다. <br/>2. 서브 계정(기프트 코인 포함)의 미지급 암호화폐는 0입니다. <br/>3. 부계정은 어떠한 종류의 특수 부계정에도 속하지 않습니다.',

      deleteName: '삭제 중인 서브 계정:',
      deleteInfo:
        '* 「확인」을 클릭한 후, 해당 서브 계정은 영구적으로 삭제되어 되찾을 수 없습니다. <br/>* 삭제 시 해당 서브 계정의 해시레이트 차트, 수익 기록 등 데이터를 되찾을 수 없게 됩니다.',
      deleteSuccess: '서브 계정 삭제 성공',
    },
    footer: {
      services: '서비스',
      Products: 'BTC.com 제품',
      Help: '도움말 센터',
      btcTool: 'BTC 툴',
      btcSmartAgent: '스마트 에이전트',
      btcComFirmware: '맞춤형 펌웨어',
      blockchainExplorer: '브라우저',
      app: 'App',
      apiDocs: 'API 문서',
      vipApplication: 'VIP 신청',
      cloudMinePoolService: '클라우드 마이닝풀 구축 서비스',
      miningTutorial: '마이닝 튜토리얼',
      FAQ: 'FAQ',
      Announcements: '공지사항',
      ticketSystem: '티켓 시스템',
    },
    pageTitle: {
      home: 'BTC.com 마이닝풀, 더욱 뛰어난 비트코인 마이닝풀',
      dashboard: '유저 페이지 - BTC.com Pool',
      miners: '채굴기관리 - BTC.com Pool',
      earningHistory: '수익 기록 - BTC.com Pool',
      earningHistoryForInvite: '리베이트 소득 - BTC.com Pool',
      poolStats: '마이닝풀 데이터 - BTC.com Pool',
      guardian: '가디언 초대 링크 - BTC.com Pool',
      guardianBind: '가디언 연동 초대 링크 - BTC.com Pool',
      subAccount: '서브 계정 생성 - BTC.com Pool',
      subAccountManage: '서브 계정 관리 - BTC.com Pool',
      settings: '설정 - BTC.com Pool',
      getCoin: '코인 증정 - BTC.com Pool',
      tools: '툴&소프트웨어 - BTC.com Pool',
      bitdeer: 'Bitdeer스페셜 칼럼 - BTC.com Pool',
      watcherExpired: '관찰자 권한 기한 만료 - BTC.com Pool',
      watcherIncorrect: '관찰자링크 오류 - BTC.com Pool',
      fundAuthorization: '자금 교환 라이선스 계약 - BTC.com Pool',
      app: 'App다운로드 - BTC.com Pool',
      invite: 'Invitation Reward - BTC.com',
    },
    breadCrumbs: {
      home: '마이닝풀 메인 페이지',
      dashboard: '유저 페이지',
      miners: '채굴기',
      earningHistory: '수익 기록',
      poolStats: '마이닝풀데이터',
      shareAccount: '공유 계정 설정',
      guardianBindingInvitation: '가디언 연동 초대 링크',
      guardianBindingLink: '가디언 연동 링크',
      subAccountManage: '서브 계정관리 ',
      hiddenSubAccounts: '서브 계정 관리 숨기기',
      settings: '설정',
      getCoin: '코인 증정',
      tools: '툴&소프트웨어',
      fundAuthorization: '자금 교환 라이선스 계약',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: '스마트 풀',
      otcNotice: `귀하는 해시레이트를 「스마트 풀 모드」로 전환하려고 합니다. 스마트풀 모드에서는 동일한 코인 유형 하의 여러 개의 주소를 지원하지 않습니다. "확인"을 클릭하면 {fundLink}를 읽고 동의한 것으로 간주합니다. `,
      fundLink: `<BTC.com 마이닝풀스마트 풀 서비스 계약>`,
      autoTip: `스마트 풀 모드를 사용하면 BTC.com 에 해시레이트를 호스팅하며 마이닝풀은 BTC, BCH, BSV 코인 유형에서 가장 높은 수익 원칙으로 전환됩니다. `,
      originalTip:
        '오리지널 코인 모드로 전환하면 SHA256 해시레이트는 별도로 선택한 코인을 발굴하며 자동으로 전환되지 않습니다.',
    },

    userCenter: '유저 센터',
    SignOut: '로그아웃',

    operation: {
      name: '조작',
      success: '조작 성공!',
      failed: '조작 실패!',
    },
    earning: {
      balanceTip:
        '결제 완료 후 BTC.com 결제 센터는 일반적으로 다음 상황을 제외하고 24시간 이내에 결제를 완료합니다.<br>1.주소가 설정되지 않았습니다.<br>2.주소는 설정되었지만 누적 결제 금액이 최소 지급 기준액에 도달하지 않은 경우<br>3.주소 추가, 주소 수정, 주소 삭제 또는 비율 수정과 같은 작업으로 인해 48시간 지급 정지 규칙이 실행되었습니다.<br>4.플랫폼 위험 통제 규칙을 발동했습니다.<br>5.디지털 화폐는 하드포크, 51% 공격 또는 기타 주요 업그레이드 또는 사고를 겪습니다.',
    },
    meta: {
      keywords:
        '비트코인, 마이닝풀, 마이닝, BTC, btc. top, bsv, eth, etc, dcr, ltc, grin, beam, F2Pool, slushpool, Antpool, via, viabtc, f2pool, antpool, bw, pool, 앤트, 채굴기',
      description:
        'BTC.com 마이닝풀은 비트코인 마이닝의 새로운 대안으로서 전통 마이닝풀에 비해 더 안정적인 마이닝풀 시스템을 보유하고 있으며 더욱 뛰어난 유저 체험, 더욱 저렴한 비용, 더욱 강력한 주변시설을 제공합니다.',
    },
    gotIt: '알겠습니다.',
    foldMe: '전체 숨기기',
    showAll: '전체 펼치기',
    copied: '복사 완료',
    more: '더 보기',
    none: '없음',
    on: '열기',
    off: '닫기',
    all: '전체',
    modify: '수정',
    submit: '제출하기',
    ok: '확인',
    cancel: '취소',
    new: '새로 추가',
    address: '주소',
    notFoundTip: '죄송합니다. 존재하지 않는 페이지입니다.',
    export: '내 보내다',
    startDate: '시작 날짜',
    endDate: '종료 날짜',
    smartPool: '스마트 풀',
    status: '상태',
    notice: '주의',
    yes: '예',
    no: '아니오',
    coin: '코인 유형',
    custom: '커스텀',
    hide: '숨기기',
    less: '숨기기',
    details: '세부',
    region: {
      cn: '북방 유니콤',
      sz: '남방 텔레콤',
      us: '미국',
      eu: '유럽',
      sg: '싱가포르',
    },
    LegalStatement:
      '본인 확인 및 동의: 본인은 쿠바, 이란, 북한, 시리아, 러시아 및 기타 국가나 관련 국가 혹은 정부 혹은 국제기구로부터 관리를 받거나 제재를 받는 국가 혹은 지역 주민이 아니며 중국 지역 주민이 아닙니다. BTC.com 에서 제공한 마이닝풀 서비스는 본인이 소재하고 있는 국가나 지역에서는 법률과 관련 정책에 부합합니다.',
    LegalStatementConfirm:
      '본인이 소재하고 있는 국가나 지역에서 BTC.com 에서 제공하는 서비스를 이용하여 발생하는 위법으로 인한 모든 법적 리스크와 책임은 전적으로 본인이 스스로에게 있습니다.',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
