import React, { useCallback, useMemo, useEffect, useReducer } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Input, Checkbox, message } from 'antd';
import {
  BtcModal,
  BtcButton,
  TooltipIcon,
  Collection,
  PageLoadingWrap,
} from 'components/widget';
import { getRandomStr, getLocaleConfig } from 'common/utils';
import { coinsConfig, LOCALES_MAP } from 'common/config';
import { useStores } from 'stores';
import CoinSelectorContent from './CoinSelectorContent';
import GroupLabel from './GroupLabel';
import styles from '../index.module.scss';
const helpCenterUrl = process.env.REACT_APP_HELP_CENTER_DOMAIN;

const AgreementCheck = ({ defaultChecked, lang, checked, onChange, t }) => {
  return (
    <div className={styles.coinSelector} style={{ userSelect: 'none' }}>
      <Checkbox
        defaultChecked={defaultChecked}
        onChange={(e) => {
          onChange?.(e.target.checked);
        }}
        checked={checked}
      >
        {t('create.Agree')}{' '}
        <a
          href={`${helpCenterUrl}/agreement/#/miningservice?lang=${LOCALES_MAP?.[lang]?.h5}`}
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        >
          {t('create.serviceAgreement')}
        </a>
      </Checkbox>
    </div>
  );
};

const ActionType = {
  Name: 'N',
  Coin: 'C',
  Region: 'R',
  Agreement: 'A',
};

const subaccountReducer = (state, action) => {
  switch (action.type) {
    case ActionType.Name:
      return { ...state, subAccountName: action.value };
    case ActionType.Coin:
      return { ...state, selectCoin: action.value, selectRegionKey: '' };
    case ActionType.Region:
      return { ...state, selectRegionKey: action.value };
    case ActionType.Agreement:
      return { ...state, agreementChecked: action.value };
    default:
      return state;
  }
};

const initialState = {
  subAccountName: '',
  selectCoin: '',
  selectRegionKey: '',
  agreementChecked: true,
};

const getRegionItem = (regionList, regionKey) => {
  return regionList.find((item) => item.region_key === regionKey);
};
const CreateSubAccountModal = observer(
  ({
    visible,
    hideModal,
    defaultSubAccountName = '',
    createByExist, // 如果是在已经存在子账号名的情况下，添加其他币种的子账号，则为{subAccountName, regionText, regionKey}
  }) => {
    const { t } = useTranslation('account');
    const { subAccountStore: store, userStore, appStore } = useStores();
    const {
      coinsRegionMap,
      coinTypeList = [],
      regionList,
      loading,
      loadingCreate,
    } = store;
    const [state, dispatch] = useReducer(subaccountReducer, initialState);

    useEffect(() => {
      store.getAccountInitData(createByExist?.subAccountName);
      return () => {};
    }, [defaultSubAccountName, store]);

    const isNotAllowCreate = useMemo(() => {
      if (createByExist) {
        return Object.keys(state)
          .filter((key) => key === 'selectCoin' || key === 'agreementChecked')
          .some((key) => !state[key]);
      } else {
        return Object.keys(state).some((key) => !state[key]);
      }
    }, [state, createByExist]);

    const handleAutoGenerateName = () => {
      let inputSubAccoutName = getRandomStr(5);
      dispatch({ type: ActionType.Name, value: inputSubAccoutName });
    };

    const handleChangeSubAccountName = (e) => {
      let inputSubAccoutName = e.target.value.trim().substring(0, 20);
      dispatch({ type: ActionType.Name, value: inputSubAccoutName });
    };

    const handleChangeCoin = (value) => {
      dispatch({ type: ActionType.Coin, value });
    };

    const handleChangeRegion = (value) => {
      dispatch({ type: ActionType.Region, value });
    };

    const handleChangeAgreementChecked = (checked) => {
      dispatch({ type: ActionType.Agreement, value: checked });
    };

    const createSuccessCallback = useCallback(
      (puid) => {
        let { subAccountName, selectCoin, selectRegionKey } = state;

        const { lang } = appStore;
        // 跳转到新创建的子账户
        const serverLang = getLocaleConfig(lang).serverLang;
        message.success(t('create.Created successfully'), 1.5);
        setTimeout(() => {
          userStore.changeAccount({
            puid,
            coin_type: selectCoin,
            name: subAccountName,
            region_conf: {
              text: {
                [serverLang]: getRegionItem(regionList, selectRegionKey)
                  ?.region_value,
              },
            },
            region_text: getRegionItem(regionList, selectRegionKey)
              ?.region_value,
          });
          userStore.getSubAccountList();
          store.setCreateLoading(false);
          setTimeout(() => {
            hideModal();
          }, 800);
        }, 1000); // 创建完成后需要等1000毫秒才能请求，数据同步问题
      },
      [state, store, appStore, userStore, regionList],
    );

    const getRegionNode = (createByExist) => {
      const regionKey = createByExist
        ? createByExist.regionKey
        : state.selectRegionKey;
      const coinRegion = coinsRegionMap?.[state.selectCoin];
      let regionNode = coinRegion[regionKey]?.region_name;
      return regionNode;
    };

    const handleClickOK = () => {
      if (!isNotAllowCreate) {
        // 执行创建
        store.setCreateLoading(true);
        const subAccountName = createByExist
          ? createByExist.subAccountName
          : state.subAccountName;
        const selectCoin = state.selectCoin;
        const regionNode = getRegionNode(createByExist);

        store.create(
          subAccountName,
          selectCoin,
          regionNode,
          createSuccessCallback,
          (err) => {
            err && message.error(err);
          },
        );
      }
    };

    const handleClickCancel = () => {
      if (store.loadingCreate) {
        return;
      }
      hideModal?.();
    };

    const isDisabledCoin = useCallback(
      (coin) => {
        return (
          !coinsRegionMap?.[coin] ||
          (coinsRegionMap?.[coin] &&
            Object.keys(coinsRegionMap?.[coin])?.every(
              (item) => coinsRegionMap[coin][item]?.user_enabled === 1, // 后端接口 user_enabled=1 代表不可用 。。
            ))
        );
      },
      [coinsRegionMap],
    );

    const isDisabledRegion = useCallback(
      (region) => {
        const { selectCoin } = state;
        return !(coinsRegionMap?.[selectCoin]?.[region]?.user_enabled === 0); // 后端接口 user_enabled=1 代表不可用 ,0代表可用。。
      },
      [coinsRegionMap, state],
    );
    const changeRegionInfoToGlobalInfo = (region) => {
      let newRegion = Object.assign({}, region);
      newRegion.region_value = t('globalZone');
      newRegion.region_code = 'GL';
      return newRegion;
    };

    const { lang } = appStore;
    const { selectCoin } = state;
    let isGlobalZone = coinsConfig[selectCoin.toLowerCase()]?.isGlobalZone;

    return (
      <BtcModal
        visible={visible}
        title={
          createByExist
            ? t('panel.Add other cryptocurrency')
            : t('create.Create a sub-account')
        }
        width={createByExist ? 550 : 740}
        onOk={handleClickOK}
        onCancel={handleClickCancel}
        okButtonProps={{
          disabled: isNotAllowCreate,
          loading: loadingCreate,
        }}
        okText={t('create.Create and Mine')}
        closable={false}
      >
        <PageLoadingWrap loading={loading}>
          <div className={styles.form}>
            {/* Input sub-account name */}
            {!createByExist && (
              <div className={styles.formItem}>
                <label>{t('create.Name')}</label>
                <div className={`${styles.formItemValue} ${styles.nameItem}`}>
                  <Input
                    placeholder={t('create.letterLimit')}
                    width={300}
                    spellCheck={false}
                    className={styles.nameInput}
                    value={state.subAccountName}
                    onChange={handleChangeSubAccountName}
                  />
                  <BtcButton
                    type="link"
                    className="flex items-center margin-left-md"
                    onClick={handleAutoGenerateName}
                  >
                    {t('create.Autogenerate')}
                  </BtcButton>
                  <div
                    className={`flex items-start secondary-text margin-top-md`}
                  >
                    <TooltipIcon className="margin-right-sm padding-top-xxs" />
                    <span>
                      {t('create.createTip', {
                        name: state.subAccountName || 'XXXX',
                      })}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {createByExist && (
              <div className="margin-bottom-lg">
                <GroupLabel
                  labels={[
                    {
                      title: t('create.Sub-account'),
                      value: createByExist.subAccountName,
                    },
                    {
                      title: t('create.Region'),
                      value: createByExist.regionText,
                    },
                  ]}
                />
              </div>
            )}
            {/* Select coin */}
            <div className={styles.formItem}>
              <label>{t('create.Currency')}</label>
              <div className={styles.formItemValue}>
                <Collection
                  onSelect={handleChangeCoin}
                  activeClassName={styles.selectorActive}
                >
                  {coinTypeList?.map((coin) => {
                    let coinHidden =
                      coinsConfig[coin.toLowerCase()]?.coinHidden;
                    return (
                      !coinHidden && (
                        <Collection.Item
                          key={coin}
                          value={coin}
                          disabled={
                            !createByExist
                              ? isDisabledCoin(coin)
                              : coin === 'ETC' || isDisabledCoin(coin)
                          }
                        >
                          <CoinSelectorContent coin={coin} />
                        </Collection.Item>
                      )
                    );
                  })}
                </Collection>
              </div>
            </div>
            {/* Select Region */}
            {!createByExist && (
              <div className={styles.formItem}>
                <label>{t('create.Region')}</label>
                <div className={styles.formItemValue}>
                  <Collection
                    onSelect={handleChangeRegion}
                    activeClassName={styles.selectorActive}
                    value={state.selectRegionKey}
                  >
                    {regionList?.map((region) => {
                      if (selectCoin && isDisabledRegion(region.region_key)) {
                        return <div key={region.region_key}></div>;
                      }
                      if (isGlobalZone) {
                        if (region.region_code !== 'SG') {
                          return <div key={region.region_key}></div>;
                        }
                        region = changeRegionInfoToGlobalInfo(region);
                      }
                      return (
                        <Collection.Item
                          key={region.region_key}
                          value={region.region_key}
                          disabled={isDisabledRegion(region.region_key)}
                        >
                          <div className={`${styles.regionSelectorItem}`}>
                            <span className={styles.regionAbbr}>
                              <span className={styles.ultr01Font}>
                                {region.region_code}
                              </span>
                            </span>
                            <span>{region.region_value}</span>
                          </div>
                        </Collection.Item>
                      );
                    })}
                  </Collection>
                  <div
                    className={`flex items-start secondary-text margin-top-md`}
                  >
                    <TooltipIcon className="margin-right-sm padding-top-xxs" />
                    <span>{t('create.regionSelectTip')}</span>
                  </div>
                </div>
              </div>
            )}
            {/* User Terms */}
            <div>
              <AgreementCheck
                t={t}
                lang={lang}
                onChange={handleChangeAgreementChecked}
                checked={state.agreementChecked}
              />
            </div>
          </div>
        </PageLoadingWrap>
      </BtcModal>
    );
  },
);

export default React.memo(CreateSubAccountModal);
